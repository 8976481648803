import styled from 'styled-components';
import Header from './Header';
import Game from './Game';
import Instructions from './Instructions';

const AppWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const MainStyle = styled.main`
	flex-grow: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #d48ec5;
`;

function App() {
  return (
    <AppWrapper>
			<Header/>
			<MainStyle>
				<Game/>
				<Instructions/>
			</MainStyle>
    </AppWrapper>
  );
}

export default App;
