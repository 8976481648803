import React from 'react';
import styled from 'styled-components';

export const ButtonRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0px;
`;

const ButtonStyle = styled.button`
	border: none;
	background-color: ${({bgColor}) => bgColor};
	color: ${({color}) => color};
	margin-right: ${({marginRight}) => marginRight || 0 }px;
	font-size: 20px;
	padding: 10px;
	cursor: pointer;
`;

export const Button = ({ type, marginRight, children, onClick }) => {

	switch (type) {
		default: {
			return (
				<ButtonStyle
					onClick={onClick}
					color='white' 
					bgColor='black' 
					marginRight={marginRight}>
					{ children }
				</ButtonStyle>
			)
		}
	}

}