import React from 'react';
import styled from 'styled-components';
import { ButtonRow, Button }from './Button.jsx';

const Screen = styled.div`
	width: ${({width}) => width}px;
	height: 100px;
	position: relative;
	background-color: white;
`;

const Fence = styled.div`
	width: 10px;
	height: 100px;
	position: absolute;
	top: 0px;
	background-color: black;
	background: linear-gradient(to right, blue, white, blue);
`;

const User = styled.div`
	width: 10px;
	height: 25px;
	top: 37.5px;
	position: absolute;
	background-color: black;
`;

class Game extends React.Component {

	constructor() {
		super();
		this.SCREEN_WIDTH = 
			window.screen.width > 480 ? 480 : window.screen.width;
		this.CLOSING_IN = 0;
		this.MOVING_LEFT = 1;
		this.MOVING_RIGHT = 2;

		this.FENCE_WIDTH = 10;

		this.fenceInterval = 0;
		this.loopInterval = 0;

	}

	initialize = (hasStarted) => {
		let highScore = localStorage.getItem('high score') || 0;
		this.setState(() => {
			return {
				hasStarted,
				highScore,
				score: 0,
				over: false,
				leftFence: 0,
				rightFence: this.SCREEN_WIDTH - this.FENCE_WIDTH,
				user: 100,
				keyIsDown: false,
				velocity: 4,
				stage: this.CLOSING_IN,
			}
		});
	}

	componentWillMount() {
		this.initialize(false);
		this.startFenceLoop();
		this.attachHandlers();
	}

	componentWillUnmount() {
		clearInterval(this.fenceInterval);
		this.detachHandlers();
	}

	attachHandlers() {
		document.body.addEventListener('keydown', this.onDown);
		document.body.addEventListener('touchstart', this.onDown);
		document.body.addEventListener('keyup', this.onUp);
		document.body.addEventListener('touchend', this.onUp);
	}

	detachHandlers() {
		document.body.removeEventListener('keydown', this.onDown);
		document.body.removeEventListener('touchstart', this.onDown);
		document.body.removeEventListener('keyup', this.onUp);
		document.body.removeEventListener('touchend', this.onUp);
	}

	onDown = (e) => {
		const SPACE = ' ';
		if (e.key && e.key.toLowerCase() !== SPACE) {
			return;
		}
		e.preventDefault();
		if (this.state.over || !this.state.hasStarted || this.state.keyIsDown) {
			return;
		}

		this.setState((state) => {
			let newScore = state.score + 1;
			let newHighScore = state.highScore;
			if (newScore > newHighScore) {
				localStorage.setItem('high score', newScore);
				newHighScore = newScore;
			}
			return {
				score: newScore,
				highScore: newHighScore,
				velocity: state.velocity * -1,
				keyIsDown: true,
			}
		})
	}

	onUp = () => {
		this.setState(() => {
			return {
				keyIsDown: false
			}
		});
	}

	startFenceLoop() {
		this.fenceInterval = setInterval(() => {
			this.setState((state) => {

				if (state.over || !state.hasStarted) {
					return;
				}

				let over = false;

				let { left, right, stage } = 
					this.adjustFence(
						state.leftFence, 
						state.rightFence, 
						state.stage
					);

				let user = state.user + state.velocity;
	
				if (user <= left + this.FENCE_WIDTH) {
					user = left + this.FENCE_WIDTH;
					over = true;
				} else if (user + this.FENCE_WIDTH >= right) {
					user = right - this.FENCE_WIDTH;
					over = true;
				}

				return {
					leftFence: left,
					rightFence: right,
					user,
					over,
					stage,
				};
			});
		}, 1000 / 30);
	}

	adjustFence(left, right, stage) {
		if (stage === this.CLOSING_IN) {
			if (right - left + this.FENCE_WIDTH > 75) {
				left = left + 2;
				right = right - 2;
			} else {
				stage = this.MOVING_LEFT
			}
		} else if (stage === this.MOVING_LEFT) {
			if (left > 0) {
				left = left - 1;
				right = right - 1;
			} else {
				stage = this.MOVING_RIGHT;
			}
		} else if (stage === this.MOVING_RIGHT) {
			if (right + this.FENCE_WIDTH < this.SCREEN_WIDTH) {
				right = right + 2;
				left = left + 2;
			} else {
				stage = this.MOVING_LEFT;
			}
		}

		return { left, right, stage };
	}

	render() {
		return (
			<>
				<p>High Score: {this.state.highScore}</p>
				<Button marginRight={10} onClick={() => this.initialize(true)}>
					{ this.state.hasStarted ? 'Restart' : 'Start' }
				</Button>
				<p>Score: {this.state.score}</p>
				<Screen width={this.SCREEN_WIDTH}>
					<Fence style={{left: this.state.leftFence}}/>
					<User style={{left: this.state.user}}/>
					<Fence style={{left: this.state.rightFence}}/>
				</Screen>
			</>
		)
	}
}

export default Game;