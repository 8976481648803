import React from 'react';
import styled from 'styled-components';

const InstructionStyle = styled.div`
	padding: 10px;
	box-sizing: border-box;
	max-width: 100%;
	font-weight: bold;
	color: white;
	background-color: black;
	color: white;
	margin-top: 20px;
	border-radius: 3px;

	h3 {
		margin: 10px 0px;
	}
`;

const Instructions = () => {
	return (
		<InstructionStyle>
			<h3>Instructions:</h3>
			<p>
				Keep box inside the fence. 
				<br/>
				<br/>
				Press 'spacebar' or tap mobile screen 
				<br/>
				to change box direction.
			</p>
		</InstructionStyle>
	)
}

export default Instructions;