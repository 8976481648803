import React from 'react';
import styled from 'styled-components';
import logo from '../images/ef-logo.png';

const HeaderStyle = styled.header`
	width: 100%;
	box-sizing: border-box;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	background-color: black;
`;

const LogoStyle = styled.img`

`

const Header = () => {
	return (
		<HeaderStyle>
			<LogoStyle src={logo}/>
		</HeaderStyle>
	)
}

export default Header;